.select-dropdown {
  z-index: 1001;
}

.select-dropdown-menu {
  z-index: 99999;
}

.ant-dialog-wrap {
  z-index: 1000;
}


.swal2-container {
  z-index: 9999999 !important;
}

.custom-row {
  background-color: #f8ead5; /* Change this to your desired background color */
}